import React from 'react';
import FleetDropDown from './FleetDropDown';
import DriverDropDown from './DriverDropDown';

const RelevantAssignDropdown = ({
  merchantFleets,
  role,
  fleets,
  groupId,
  updateFleet,
  groupDetails,
  assignAllFleets,
  setLoadingCard,
  drivers,
  updateDriver,
  assignAllDrivers,
  dateTime,
  bound,
  trips,
  assignType,
}) => {
  if (
    merchantFleets == true &&
    role != 'fleetmanager' &&
    assignType == 'fleet'
  ) {
    return (
      <FleetDropDown
        fleets={fleets}
        groupId={groupId}
        updateFleet={updateFleet}
        groupDetails={groupDetails}
        assignAllFleets={assignAllFleets}
        setLoadingCard={setLoadingCard}
        trips={trips}
      />
    );
  }

  return (
    <DriverDropDown
      drivers={drivers}
      groupId={groupId}
      updateDriver={updateDriver}
      groupDetails={groupDetails}
      assignAllDrivers={assignAllDrivers}
      setLoadingCard={setLoadingCard}
      dateTime={dateTime}
      bound={bound}
      trips={trips}
      tripsLength={trips.length}
    />
  );
};

export default RelevantAssignDropdown;
