import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faBookmark,
  faSave,
} from '@fortawesome/free-solid-svg-icons';
import CancelConfirm from './CancelConfirm';
import DriverOption from './DriverOption';
import './driver-option.css';
import moment from 'moment';

const DriverDropDown = ({
  drivers,
  groupId,
  updateDriver,
  groupDetails,
  assignAllDrivers,
  setLoadingCard,
  dateTime,
  bound,
  tripsLength,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [confirmAssign, setConfirmAssign] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [singleTripAlert, SetSingleTripAlert] = useState(false);
  const [tripReason, setTripReason] = useState('');
  const [filteredDrivers, setFilteredDrivers] = useState(drivers.vehicles);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleDriverSelect = (driver) => {
    setSelectedDriver(driver);
    setDropdownOpen(false);
  };

  const handleDriverAssign = () => {
    if (selectedDriver) {
      updateDriver(selectedDriver, groupId, tripReason);
      setConfirmAssign(false);
      setLoadingCard(true);
      if (selectedDriver.id == -1) {
        setSelectedDriver(null);
      }
    }
  };

  useEffect(() => {
    if (groupDetails) {
      const savedDriver = drivers?.vehicles?.find(
        (driver) => driver?.user_id === groupDetails?.driver_id
      );
      if (savedDriver) {
        setSelectedDriver(savedDriver);
      }
    }
  }, []);

  useEffect(() => {
    handleDriverAssign();
  }, [assignAllDrivers]);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Handle the search query and update the filtered drivers list
  useEffect(() => {
    const updatedFilteredDrivers = drivers?.vehicles?.filter((driver) => {
      const driverName = driver.username + ' ' + driver.usersurname;
      return driverName.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setFilteredDrivers(updatedFilteredDrivers);
  }, [searchQuery, drivers]);

  const alreadyAssigned = (driver) => {
    const driverTrips = getDriverTrips(driver.user_id);
    const card_trip_type = bound == 'Inbound' ? 0 : 1;
    if (driverTrips && groupDetails) {
      const hasConflict = driverTrips.some(
        (trip) =>
          trip.date == groupDetails.date &&
          trip.timeslot == groupDetails.timeslot &&
          trip.trip_type == card_trip_type
      );
      return hasConflict;
    } else if (driverTrips && dateTime) {
      const [date, time] = dateTime.split(' - ');
      const hasConflict = driverTrips.some((trip) => {
        // Parse trip.date into a Moment object
        const tripDate = moment(trip.date);
        // Parse card date into a Moment object
        const cardDate = moment(date, 'DD/MM/YYYY');
        // Compare the dates and timeslot
        return (
          tripDate.isSame(cardDate, 'date') &&
          trip.timeslot == time &&
          trip.trip_type == card_trip_type
        );
      });
      return hasConflict;
    }
    return false;
  };

  const getSelectedDriver = () => {
    if (selectedDriver) {
      return selectedDriver.username + ' ' + selectedDriver.usersurname;
    } else {
      return 'Driver';
    }
  };

  const getDriverTrips = (user_id) => {
    const driverTrips = drivers.grouped_trips[user_id];
    if (driverTrips) {
      return driverTrips;
    }
    return null;
  };

  const handleConfirm = () => {
    setDropdownOpen(false);
    console.log('look: ', tripsLength);
    if (tripsLength == 1) {
      SetSingleTripAlert(true);
    } else {
      if (selectedDriver) {
        console.log('check: ', tripsLength);
        setConfirmAssign(true);
        SetSingleTripAlert(false);
      } else {
        setConfirmAssign(false);
      }
    }
  };

  const reasons = [
    { id: 1, title: 'Adhoc Passenger', value: 'Adhoc Passenger' },
    { id: 2, title: 'Adhoc Trip', value: 'Adhoc trip' },
    { id: 3, title: 'Late Booking', value: 'late booking' },
    { id: 4, title: 'Only Trip in Timeslot', value: 'only trip in timeslot' },
    {
      id: 5,
      title: 'Other Passenger Cancelled',
      value: 'other passenger cancelled',
    },
    { id: 6, title: 'Outlier', value: 'outlier' },
  ];

  const handleTripReasonChange = (event) => {
    setTripReason(event.target.value);
  };

  return (
    <div className="w-full flex overflow-visible">
      <div
        className={`h-5 max-h-20 w-full px-2 py-2 text-gray-700 flex items-center justify-between focus:outline-none rounded-l-full border border-gray-700 ${
          groupDetails?.status == 'complete'
            ? 'bg-gray-200 cursor-not-allowed'
            : 'bg-gray-100 hover:bg-gray-200'
        }  `}
        onClick={groupDetails?.status == 'complete' ? {} : handleDropdownToggle}
        data-testid={'driver-dropdown'}>
        <span className=" ml-2 text-xs font-semibold">
          {getSelectedDriver()}
        </span>
        <FontAwesomeIcon icon={faChevronDown} size={'xs'} />
      </div>
      <div
        disabled={singleTripAlert}
        onClick={groupDetails?.status == 'complete' ? {} : handleConfirm}
        data-testid={'save-btn'}
        className={`max-h-20 px-2 py-2 rounded-r-full w-8 h-5 border border-gray-700 flex items-center justify-between focus:outline-none text-gray-700 ${
          groupDetails?.status == 'complete'
            ? 'bg-gray-200 cursor-not-allowed'
            : 'bg-gray-100 hover:bg-gray-200'
        } `}>
        <FontAwesomeIcon icon={faSave} size="xs" className="mr-1" />
      </div>
      {dropdownOpen && (
        <div
          className="absolute z-10 mt-5 w-64 bg-white shadow-lg rounded-md overflow-y-scroll scrollable-dropdown"
          style={{ height: 'auto', maxHeight: '120px' }}>
          <input
            type="text"
            autoFocus
            className="px-2 py-1 text-xs text-gray-700 w-full"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
            aria-valuenow={selectedDriver}>
            {[
              { id: -1, user_id: -1, username: 'Reject', usersurname: 'trip' },
              ...filteredDrivers,
            ].map((driver, index) => (
              <DriverOption
                data-testid={`option-${driver.id}`}
                key={driver.id}
                setSelectedDriver={handleDriverSelect}
                driver={driver}
                driverTrips={index == 0 ? null : getDriverTrips(driver.user_id)}
                groupDetails={groupDetails}
                dateTime={dateTime}
                alreadyAssigned={index == 0 ? false : alreadyAssigned(driver)}
              />
            ))}
          </div>
        </div>
      )}
      {confirmAssign && (
        <>
          <div
            style={{ zIndex: 10 }}
            className="absolute top-0 left-0 w-full h-full bg-black opacity-50"
            onClick={() => setConfirmAssign(false)}></div>
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
            <CancelConfirm
              data-testid={'confirm-assign'}
              title={'Assign Driver'}
              body={
                'You are about to assign a driver and send out communications. Are you sure?'
              }
              onNoClick={() => {
                console.log('no click: ', tripsLength);
                setConfirmAssign(false);
              }}
              onYesClick={() => {
                handleDriverAssign();
              }}
            />
          </div>
        </>
      )}

      {singleTripAlert && (
        <div className="absolute z-40 top-0 left-0 w-full h-full flex items-center justify-center">
          <div className="relative bg-white rounded-md shadow-lg p-6">
            <h2 className="text-xl text-gray-700 font-semibold mb-4">
              Select Trip Reason:
            </h2>
            <select
              value={tripReason}
              onChange={handleTripReasonChange}
              className="w-1/2 p-2 border rounded-md mb-4 pr-2">
              <option value="">Select Trip Reason</option>
              {reasons.map((reason) => (
                <option key={reason.id} value={reason.value}>
                  {reason.title}
                </option>
              ))}
            </select>
            <button
              disabled={!singleTripAlert}
              onClick={() => {
                handleDriverAssign();
              }}
              className="px-4 py-2 bg-purple-500 text-white rounded-md hover:bg-purple-600">
              Submit
            </button>
            <button
              disabled={!singleTripAlert}
              onClick={() => SetSingleTripAlert(false)}
              className="ml-2 px-4 py-2 bg-gray-700 text-white rounded-md hover:bg-gray-600">
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DriverDropDown;
