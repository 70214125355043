import React, { useState, useEffect, useRef } from 'react';
import Axios from 'axios';
import ActionCable from 'actioncable';
import PassengerMenu from './enhancedGroupedTripsComponents/PassengerMenu';
import TripMenu from './enhancedGroupedTripsComponents/TripMenu';
import { isEmpty } from '../../tools/helpers';
import {
  getFormattedDate,
  getFormattedTimeSlot,
  getFormattedTimeSlot24,
} from '../../tools/date.util';
import EnhancedTripMap from './enhancedGroupedTripsComponents/tripBookingMap/EnhancedTripMap';
import './enhancedGroupedTripsComponents/enhanced_grouped_trips.css';
import { Steps } from 'intro.js-react';

export const EnhancedGroupedTrips = ({ currentUser, role }) => {
  const [introState, setIntroState] = useState({
    stepsEnabled: false,
    initialStep: 0,
    options: {
      showProgress: true,
      showBullets: false,
      tooltipClass: 'etapathTooltip',
    },
    steps: [
      {
        element: '.groupingIntro',
        title:
          "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Groupings",
        intro: 'Groupings are made by selecting passengers on the map.',
      },
      {
        element: '.introMap',
        title:
          "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Grouping Map",
        intro:
          "The passengers' locations will appear on the map as pins. Select a number of pins together and select the 'create grouping' button to create a grouping. <br><br> <span class='tooltip-note'><b><i class='fas fa-comment-dots'></i> Groupings can be altered by adding or removing specific passenger pins from the grouping.",
      },
      {
        element: '.selectCompany',
        title:
          "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Select Company",
        intro: "Select a company to view it's bookings.",
      },
      {
        element: '.selectAddress',
        title:
          "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Select Address",
        intro: "Select the company's address.",
      },
      {
        element: '.tripType',
        title:
          "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Trip Type",
        intro: 'Select the type of trip.',
      },
      {
        element: '.filters',
        title:
          "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Filters",
        intro:
          'Search for trips according to whether or not they are grouped or assigned.',
      },
      {
        element: '.tripStatus',
        title:
          "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Trip Status",
        intro: 'View the status of various trips.',
      },
      {
        element: '.selectDateTime',
        title:
          "<i class='fas fa-map-marker-alt' style='margin-right: 8px;'></i> Date and Time",
        intro:
          'Select the date and time of the bookings you are searching for.',
      },
    ],
  });

  const [pageType, setPageType] = useState(
    role != 'fleetmanager' ? 'ungrouped' : 'grouped'
  );
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('All Companies');

  const [selectedDate, setSelectedDate] = useState(null);
  const [allDate, setAllDate] = useState(true);
  const [timeSlot, setTimeSlot] = useState(null);
  const [allTimeslots, setAllTimeslots] = useState(true);

  const [allTrips, setAllTrips] = useState([]);
  const [allGroups, setAllGroups] = useState([]);
  const [allAsssignedGroups, setAllAsssignedGroups] = useState();

  const [bound, setBound] = useState('Inbound');

  const [ungroupedAddresses, setUngroupedAddresses] = useState([]);
  const [groupedAddresses, setGroupedAddresses] = useState([]);
  const [assignedGroupedAddresses, setAssignedGroupedAddresses] = useState([]);

  const [addresses, setAddressess] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState('None');

  const [inboundTrips, setInboundTrips] = useState([]);
  const [outboundTrips, setOutboundTrips] = useState([]);

  const [inboundGroupedTrips, setInboundGroupedTrips] = useState([]);
  const [outboundGroupedTrips, setOutboundGroupedTrips] = useState([]);

  const [inboundAssignedGroupedTrips, setInboundAssignedGroupedTrips] =
    useState([]);
  const [outboundAssignedGroupedTrips, setOutboundAssignedGroupedTrips] =
    useState([]);

  const [filteredTrips, setFilteredTrips] = useState(inboundTrips);
  const [filteredGroupedTrips, setFilteredGroupedTrips] =
    useState(inboundGroupedTrips);
  const [filteredGroupedAssignedTrips, setFilteredAssignedGroupedTrips] =
    useState(inboundAssignedGroupedTrips);
  const [groupTripSet, setGroupTripSet] = useState([]);

  const [assignedGroups, setAssignedGroups] = useState([]);
  const [unassignedGroups, setUnassignedGroups] = useState([]);
  const [assignedLocations, setAssignedLocations] = useState([]);

  const [drivers, setDrivers] = useState([]);

  const [selectedTrips, setSelectedTrips] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [selectedAssigned, setSelectedAssigned] = useState([]);

  const [showAssignedTrips, setShowAssignedTrips] = useState(true);
  const [showGroupedTrips, setShowGroupedTrips] = useState(true);
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const selectedCompanyRef = useRef();
  const prevAddresses = useRef(addresses);
  const [refresh, setRefresh] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [centerFocus, setCenterFoucs] = useState({
    lat: -28.48322,
    lng: 24.676997,
  });
  const [focusPins, setFocusPins] = useState([{}]);
  const [itemToHighLight, setItemToHighLight] = useState(null);
  const [groupToHighLight, setGroupToHighLight] = useState(null);

  const [merchantFleets, setMerchantFleets] = useState(false);
  const [fleets, setFleets] = useState([]);
  const [selectedFleetFilter, setSelectedFleetFilter] = useState({
    label: 'All fleets',
    value: 'all',
  });
  const [fleetFilterOptions, setFleetFilterOptions] = useState([]);
  const managedTraining = (start) => {
    setIntroState((prevState) => ({
      ...prevState,
      stepsEnabled: start,
    }));
  };

  function handleCenterFocus(latitude, longitute) {
    setCenterFoucs({
      lat: latitude,
      lng: longitute,
    });
  }

  function handleMarkerHighlight(id) {
    setGroupToHighLight(null);
    if (id === itemToHighLight) {
      setItemToHighLight(null);
    } else {
      setItemToHighLight(id);
    }
  }

  function handleGroupMarkerHighlight(id) {
    setItemToHighLight(null);
    if (id === groupToHighLight) {
      setGroupToHighLight(null);
    } else {
      setGroupToHighLight(id);
    }
  }

  function handleDateSelect(date) {
    setSelectedDate(date);

    if (!selectedDate) {
      setAllDate(true);
    } else {
      setAllDate(false);
    }
  }

  function handleTimeSelect(timeSlot) {
    setTimeSlot(timeSlot);

    if (!timeSlot) {
      setAllTimeslots(true);
    } else {
      setAllTimeslots(false);
    }
  }

  function handleRefresh() {
    setRefresh(!refresh);
  }

  function handleCompanySelect(company) {
    setSelectedCompany(company);
  }

  function handleAddressSelect(address) {
    setSelectedAddress(address);
  }

  useEffect(() => {
    let company = companies.find((company) => company.id == selectedCompany);

    setMerchantFleets(company?.merchant_fleets ? true : false);

    if (company?.fleets) {
      setFleets(company?.fleets);
    }
  }, [selectedCompany]);

  // get companies on load
  useEffect(() => {
    setIsLoadingPage(true);
    Axios({
      method: 'get',
      url: '/active_companies',
      headers: { Accept: 'application/json' },
    }).then((res) => {
      setCompanies(res.data.companies);
      setIsLoadingPage(false);
    });
  }, []);

  // get trips when company is selected (ungrouped)
  useEffect(() => {
    if (selectedCompany !== 'All Companies') {
      setIsLoadingPage(true);
      let url = '/grouped_trips/ungrouped_trips';
      url += `?date=${null}`;
      url += `&timeslot=${null}`;
      url += `&company=${selectedCompany}`;
      url += `&all=${true}`;
      Axios({
        method: 'get',
        url: url,
        headers: { Accept: 'application/json' },
      })
        .then((res) => {
          const trips = res.data.trips_bounded;
          for (const [add, bounds] of Object.entries(trips)) {
            for (const [bound, trips] of Object.entries(bounds)) {
              if (!isEmpty(trips)) {
                trips.forEach((trip) => {
                  trip.location_latitude += Math.random() / 2500;
                  trip.location_longitude += Math.random() / 2500;
                  trip.destination_latitude += Math.random() / 2500;
                  trip.destination_longitude += Math.random() / 2500;
                });
              }
            }
          }
          const addresses = Object.keys(trips);
          setUngroupedAddresses(addresses);
          setAllTrips(trips);
          setIsLoadingPage(false);
        })
        .catch((err) => {
          setIsLoadingPage(false);
          console.error(err);
        });
    } else {
      setAllTrips([]);
      setFilteredTrips([]);
      setUngroupedAddresses([]);
      setInboundTrips([]);
      setOutboundTrips([]);
    }
  }, [selectedCompany, selectedAddress, refresh]);

  // get grouped trips when company is selected (grouped + unassigned)
  useEffect(() => {
    if (selectedCompany !== 'All Companies') {
      setIsLoadingPage(true);
      let url = '/grouped_trips/grouped_trips_bounded';
      url += `?date=${'?'}`;
      url += `&timeslot=${null}`;
      url += `&company=${selectedCompany}`;
      url += '&filter=unassigned';
      url += `&all=${true}`;
      Axios({
        method: 'get',
        url: url,
        headers: { Accept: 'application/json' },
      })
        .then((res) => {
          const groupedTrips = res.data.grouped_with_trips_bounded;
          for (const [add, bounds] of Object.entries(groupedTrips)) {
            for (const [bound, ids] of Object.entries(bounds)) {
              for (const [id, trips] of Object.entries(ids))
                if (!isEmpty(trips)) {
                  trips.forEach((trip) => {
                    trip.location_latitude += Math.random() / 2500;
                    trip.location_longitude += Math.random() / 2500;
                    trip.destination_latitude += Math.random() / 2500;
                    trip.destination_longitude += Math.random() / 2500;
                  });
                }
            }
          }
          const grouped_addresses = Object.keys(groupedTrips);
          setGroupedAddresses(grouped_addresses);
          setAllGroups(groupedTrips);
          setIsLoadingPage(false);
        })
        .catch((err) => {
          setIsLoadingPage(false);
          console.error(err);
        });
    } else {
      setAllGroups([]);
      setFilteredGroupedTrips([]);
      setGroupedAddresses([]);
      setInboundGroupedTrips([]);
      setOutboundGroupedTrips([]);
    }
  }, [selectedCompany, selectedAddress, assignedGroups]);

  // get grouped trips when company is selected (grouped + assigned)
  useEffect(() => {
    if (selectedCompany !== 'All Companies') {
      setIsLoadingPage(true);
      let url = '/grouped_trips/grouped_trips_bounded';
      url += `?date=${'?'}`;
      url += `&timeslot=${null}`;
      url += `&company=${selectedCompany}`;
      url += '&filter=assigned';
      url += `&all=${true}`;
      Axios({
        method: 'get',
        url: url,
        headers: { Accept: 'application/json' },
      })
        .then((res) => {
          const groupedTrips = res.data.grouped_with_trips_bounded;
          for (const [add, bounds] of Object.entries(groupedTrips)) {
            for (const [bound, ids] of Object.entries(bounds)) {
              for (const [id, trips] of Object.entries(ids))
                if (!isEmpty(trips)) {
                  trips.forEach((trip) => {
                    trip.location_latitude += Math.random() / 2500;
                    trip.location_longitude += Math.random() / 2500;
                    trip.destination_latitude += Math.random() / 2500;
                    trip.destination_longitude += Math.random() / 2500;
                  });
                }
            }
          }
          const grouped_addresses = Object.keys(groupedTrips);
          setAssignedGroupedAddresses(grouped_addresses);
          setAllAsssignedGroups(groupedTrips);
          setIsLoadingPage(false);
        })
        .catch((err) => {
          setIsLoadingPage(false);
          console.error(err);
        });
    } else {
      setAllAsssignedGroups([]);
      setFilteredAssignedGroupedTrips([]);
      setAssignedGroupedAddresses([]);
      setInboundAssignedGroupedTrips([]);
      setOutboundAssignedGroupedTrips([]);
    }
  }, [selectedCompany, selectedAddress, assignedGroups]);

  //append groupedTrip Address and unassigned passenger Addresses:
  useEffect(() => {
    // combine addresses from all API calls
    const allAddresses = new Set([
      ...(ungroupedAddresses || []),
      ...(groupedAddresses || []),
      ...(assignedGroupedAddresses || []),
    ]);
    setAddressess(Array.from(allAddresses));
  }, [ungroupedAddresses, groupedAddresses, assignedGroupedAddresses]);

  //trip filters
  useEffect(() => {
    if (selectedCompany != 'All Companies') {
      //get the trips we're working with
      const trips = bound == 'Inbound' ? inboundTrips : outboundTrips;
      const groups =
        bound == 'Inbound' ? inboundGroupedTrips : outboundGroupedTrips;
      const assigned =
        bound == 'Inbound'
          ? inboundAssignedGroupedTrips
          : outboundAssignedGroupedTrips;

      //make copies
      let _trips =
        bound == 'Inbound'
          ? JSON.parse(JSON.stringify(inboundTrips))
          : JSON.parse(JSON.stringify(outboundTrips));
      let _groups =
        bound == 'Inbound'
          ? JSON.parse(JSON.stringify(inboundGroupedTrips))
          : JSON.parse(JSON.stringify(outboundGroupedTrips));
      let _assigned =
        bound == 'Inbound'
          ? JSON.parse(JSON.stringify(inboundAssignedGroupedTrips))
          : JSON.parse(JSON.stringify(outboundAssignedGroupedTrips));

      const groupsArray = Object.entries(groups);
      const assignedArray = Object.entries(assigned);

      //iterate through unassigned + ungrouped
      _trips = _trips.filter((trip) => {
        if (
          selectedDate &&
          getFormattedDate(trip.date) !== getFormattedDate(selectedDate)
        )
          return false;
        if (timeSlot && trip.timeslot == timeSlot) return false;
        return true;
      });

      //unassigned + grouped
      groupsArray.forEach(([groupId, trips]) => {
        if (!trips[0]) {
          delete _groups[groupId];
          return;
        }

        if (
          selectedDate &&
          getFormattedDate(trips[0].date) !== getFormattedDate(selectedDate)
        ) {
          delete _groups[groupId];
          return;
        }
        if (timeSlot && trips[0]?.timeslot !== timeSlot) {
          delete _groups[groupId];
          return;
        }
        if (selectedFleetFilter?.value != 'all') {
          if (selectedFleetFilter?.value == 'unassigned') {
            if (trips[0]?.fleet_id != null) {
              delete _groups[groupId];
              return;
            }
          } else if (trips[0]?.fleet_id != selectedFleetFilter?.value) {
            delete _groups[groupId];
            return;
          }
        }
      });

      assignedArray.forEach(([groupId, trips]) => {
        if (!trips[0]) {
          delete _assigned[groupId];
          return;
        }
        if (
          selectedDate &&
          getFormattedDate(trips[0].date) !== getFormattedDate(selectedDate)
        ) {
          delete _assigned[groupId];
          return;
        }
        if (timeSlot && trips[0]?.timeslot !== timeSlot) {
          delete _assigned[groupId];
          return;
        }
        /*if(selectedFleetFilter?.value != 'all'){
          if (selectedFleetFilter?.value == 'unassigned' && trips[0]?.fleet_id != null) { delete _assigned[groupId]; return;}
          if (trips[0]?.fleet_id != selectedFleetFilter?.value) {{ delete _assigned[groupId]; return;}}
        }*/
      });

      setFilteredTrips(_trips);
      setFilteredAssignedGroupedTrips(_assigned);
      setFilteredGroupedTrips(_groups);

      if (selectedAddress) {
        const allFleetTrips = Object.entries(groups);
        let _fleetFilterOptions = fleets.map((fleet) => {
          const fleetTrips = allFleetTrips?.filter(
            ([group, trips]) => trips?.[0]?.fleet_id == fleet.id
          );
          return {
            label: `${fleet.name} ${
              fleetTrips?.length ? fleetTrips?.length : 0
            }`,
            value: fleet.id,
          };
        });

        const unassignedFleetTrips = allFleetTrips?.filter(
          ([group, trips]) => trips?.[0]?.fleet_id == null
        );
        _fleetFilterOptions.unshift(
          ...[
            {
              label: `All fleets ${allFleetTrips.length}`,
              value: 'all',
            },
            {
              label: `Unassigned ${
                unassignedFleetTrips?.length ? unassignedFleetTrips?.length : 0
              }`,
              value: 'unassigned',
            },
          ]
        );

        setFleetFilterOptions(_fleetFilterOptions);
        let _selectedFleetFilter = _fleetFilterOptions.find(
          (option) => option.value == selectedFleetFilter.value
        );
        setSelectedFleetFilter(_selectedFleetFilter);
      }
    } else {
      setFilteredTrips([]);
      setFilteredGroupedTrips([]);
      setFilteredAssignedGroupedTrips([]);
      setSelectedFleetFilter({
        label: 'All fleets',
        value: 'all',
      });
    }
  }, [
    bound,
    selectedDate,
    timeSlot,
    selectedAddress,
    assignedGroups,
    inboundTrips,
    outboundTrips,
    inboundGroupedTrips,
    outboundGroupedTrips,
    inboundAssignedGroupedTrips,
    outboundAssignedGroupedTrips,
    selectedFleetFilter?.value,
  ]);

  //search filter
  useEffect(() => {
    const trips = bound == 'Inbound' ? inboundTrips : outboundTrips;
    const activeList = trips;

    const searchResults = activeList.filter((trip) => {
      // Perform search filtering based on location, destination, and full_name
      const {
        location,
        destination,
        full_name,
        driver,
        grouped_trip_id,
        date,
        timeslot,
      } = trip;
      const searchQueryLower = searchQuery.toLowerCase();
      const formattedDate = getFormattedDate(date);
      const formattedTimeslot = getFormattedTimeSlot24(timeslot);

      return (
        ((location && location.toLowerCase().includes(searchQueryLower)) ||
          (destination &&
            destination.toLowerCase().includes(searchQueryLower)) ||
          (full_name && full_name.toLowerCase().includes(searchQueryLower)) ||
          (driver && driver.toLowerCase().includes(searchQueryLower)) ||
          grouped_trip_id === searchQueryLower) &&
        (!selectedDate || formattedDate === getFormattedDate(selectedDate)) &&
        (!timeSlot || formattedTimeslot === timeSlot.replace(/\s+/g, ''))
      );
    });
    setFilteredTrips(searchResults);

    const groups =
      bound == 'Inbound' ? inboundGroupedTrips : outboundGroupedTrips;
    const groupedSearchResults = Object.entries(groups).reduce(
      (accumulator, [groupId, trips]) => {
        const hasMatch = trips.some((trip) => {
          const {
            location,
            destination,
            full_name,
            driver,
            grouped_trip_id,
            date,
            timeslot,
          } = trip;
          const searchQueryLower = searchQuery.toLowerCase();
          const formattedDate = getFormattedDate(date);
          const formattedTimeslot = getFormattedTimeSlot24(timeslot);

          return (
            ((location && location.toLowerCase().includes(searchQueryLower)) ||
              (destination &&
                destination.toLowerCase().includes(searchQueryLower)) ||
              (full_name &&
                full_name.toLowerCase().includes(searchQueryLower)) ||
              (driver && driver.toLowerCase().includes(searchQueryLower)) ||
              grouped_trip_id == searchQueryLower) &&
            (!selectedDate ||
              formattedDate === getFormattedDate(selectedDate)) &&
            (!timeSlot || formattedTimeslot === timeSlot.replace(/\s+/g, '')) &&
            (selectedFleetFilter?.value != 'all'
              ? selectedFleetFilter?.value == 'unassigned'
                ? trip?.fleet_id != null
                  ? false
                  : true
                : trip?.fleet_id != selectedFleetFilter?.value
                ? false
                : true
              : true)
          );
        });

        if (hasMatch) {
          // Include the entire group in the results if any trip matches the search query
          accumulator[groupId] = trips;
        }
        return accumulator;
      },
      {}
    );
    setFilteredGroupedTrips(groupedSearchResults);

    const assigned =
      bound == 'Inbound'
        ? inboundAssignedGroupedTrips
        : outboundAssignedGroupedTrips;

    const assignedSearchResults = Object.entries(assigned).reduce(
      (accumulator, [groupId, trips]) => {
        const hasMatch = trips.some((trip) => {
          const {
            location,
            destination,
            full_name,
            grouped_trip_id,
            date,
            timeslot,
          } = trip;
          const searchQueryLower = searchQuery.toLowerCase();
          const formattedDate = getFormattedDate(date);
          const formattedTimeslot = getFormattedTimeSlot24(timeslot);

          const matchedGroupedTrip = assignedGroups.find(
            (obj) => obj.id == groupId
          );
          // Access the vehicle ID from the matched grouped trip's tripDetails
          const vehicleId = matchedGroupedTrip?.vehicle_id;

          // Find the driver in drivers.vehicles collection using the vehicle ID
          const driver = drivers.vehicles.find(
            (vehicle) => vehicle.id === vehicleId
          );
          const driverFullName = driver
            ? `${driver.username.trim()} ${driver.usersurname.trim()}`
            : '';

          return (
            ((location && location.toLowerCase().includes(searchQueryLower)) ||
              (destination &&
                destination.toLowerCase().includes(searchQueryLower)) ||
              (full_name &&
                full_name.toLowerCase().includes(searchQueryLower)) ||
              (driver &&
                driverFullName.toLowerCase().includes(searchQueryLower)) ||
              grouped_trip_id == searchQueryLower) &&
            (!selectedDate ||
              formattedDate === getFormattedDate(selectedDate)) &&
            (!timeSlot || formattedTimeslot === timeSlot.replace(/\s+/g, ''))
          );
        });

        if (hasMatch) {
          // Include the entire group in the results if any trip matches the search query
          accumulator[groupId] = trips;
        }
        return accumulator;
      },
      {}
    );
    setFilteredAssignedGroupedTrips(assignedSearchResults);
  }, [
    searchQuery,
    bound,
    selectedDate,
    timeSlot,
    selectedAddress,
    assignedGroups,
    inboundTrips,
    outboundTrips,
    inboundGroupedTrips,
    outboundGroupedTrips,
    inboundAssignedGroupedTrips,
    outboundAssignedGroupedTrips,
  ]);

  //Combine GroupedTrip details with Grouped Trips(locations)
  useEffect(() => {
    // Create an empty list to store the matched trips
    const matched_trips = [];
    const assigned_trip_locations = [];

    if (!filteredGroupedAssignedTrips || !assignedGroups) {
      return;
    } else {
      // Loop through each grouped trip
      Object.entries(filteredGroupedAssignedTrips).forEach(
        ([group_id, trips]) => {
          // Find the corresponding trip details
          const tripDetails = assignedGroups.find(
            (details) => details.id == group_id
          );
          // If the trip details exist, push the matched trip to matched_trips
          if (tripDetails) {
            matched_trips.push({ group_id, tripDetails, trips });
            assigned_trip_locations.push({ group_id, trips });
          }
        }
      );

      // Do something with matched_trips
      setGroupTripSet(matched_trips);
      setAssignedLocations(assigned_trip_locations);
    }
  }, [filteredGroupedAssignedTrips, assignedGroups, pageType]);

  // For TripMap
  const handleMarkerClick = (_e, item) => {
    const index = selectedTrips.findIndex(
      (loc) => loc.full_name === item.full_name
    );
    if (index === -1) {
      setSelectedTrips([...selectedTrips, item]);
    } else {
      setSelectedTrips(
        selectedTrips.filter((loc) => loc.full_name !== item.full_name)
      );
    }
  };

  const handleGroupMarkerClick = (_e, trip, _index) => {
    if (
      !isEmpty(selectedGroup) &&
      trip.grouped_trip_id === selectedGroup[0].grouped_trip_id
    ) {
      setSelectedGroup([]);
      return;
    }
    const group_id = trip.grouped_trip_id;
    if (bound === 'Inbound') {
      setSelectedGroup(inboundGroupedTrips[group_id]);
    } else if (bound == 'Outbound') {
      setSelectedGroup(outboundGroupedTrips[group_id]);
    }
  };

  const handleAssignedMarkerClick = (_e, trip, _index) => {
    if (
      !isEmpty(selectedGroup) &&
      trip.grouped_trip_id === selectedGroup[0].grouped_trip_id
    ) {
      setSelectedAssigned([]);
      return;
    }
    const group_id = trip.grouped_trip_id;
    if (bound === 'Inbound') {
      setSelectedGroup(inboundAssignedGroupedTrips[group_id]);
    } else if (bound == 'Outbound') {
      setSelectedGroup(outboundAssignedGroupedTrips[group_id]);
    }
  };

  // Post request to create action of grouped_trips controller
  const handleCreateGroup = () => {
    if (bound === 'Inbound') {
      const newInboundTrips = [].concat(
        inboundTrips.filter((obj1) =>
          selectedTrips.every((obj2) => obj1.id !== obj2.id)
        ),
        selectedTrips.filter((obj2) =>
          inboundTrips.every((obj1) => obj2.id !== obj1.id)
        )
      );
      setInboundTrips(newInboundTrips);
    } else if (bound === 'Outbound') {
      const newOutboundTrips = [].concat(
        outboundTrips.filter((obj1) =>
          selectedTrips.every((obj2) => obj1.id !== obj2.id)
        ),
        selectedTrips.filter((obj2) =>
          outboundTrips.every((obj1) => obj2.id !== obj1.id)
        )
      );
      setOutboundTrips(newOutboundTrips);
    }
    setSelectedTrips([]);
    const data = {
      trips: selectedTrips,
      timeslot: getFormattedTimeSlot24(timeSlot),
      company: selectedCompany,
      date: getFormattedDate(selectedDate),
    };
    setIsLoadingPage(true);
    Axios({
      method: 'post',
      url: '/grouped_trips',
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        const data = res.data.trips;
        const group_id = data[0].grouped_trip_id;
        if (bound === 'Inbound') {
          data.forEach((trip) => {
            trip.location_latitude += Math.random() / 2500;
            trip.location_longitude += Math.random() / 2500;
          });
          let newInboundGroupedTrips = Object.assign({}, inboundGroupedTrips);
          newInboundGroupedTrips[group_id] = data;
          setInboundGroupedTrips(newInboundGroupedTrips);
          setSelectedGroup(newInboundGroupedTrips[group_id]);
        } else if (bound === 'Outbound') {
          data.forEach((trip) => {
            trip.destination_latitude += Math.random() / 2500;
            trip.destination_longitude += Math.random() / 2500;
          });
          let newOutboundGroupedTrips = Object.assign({}, outboundGroupedTrips);
          newOutboundGroupedTrips[group_id] = data;
          setOutboundGroupedTrips(newOutboundGroupedTrips);
          setSelectedGroup(newOutboundGroupedTrips[group_id]);
        }
        setIsLoadingPage(false);
        retrieveAssignedGroups();
        retrieveUnassignedGroups();
      })
      .catch((err) => console.error(err));
  };

  // Patch request to update action of grouped_trips controller for re-grouping

  const handleReGroup = () => {
    if (bound === 'Inbound') {
      const newInboundTrips = [].concat(
        inboundTrips.filter((obj1) =>
          selectedTrips.every((obj2) => obj1.id !== obj2.id)
        ),
        selectedTrips.filter((obj2) =>
          inboundTrips.every((obj1) => obj2.id !== obj1.id)
        )
      );
      setInboundTrips(newInboundTrips);
    } else if (bound === 'Outbound') {
      const newOutboundTrips = [].concat(
        outboundTrips.filter((obj1) =>
          selectedTrips.every((obj2) => obj1.id !== obj2.id)
        ),
        selectedTrips.filter((obj2) =>
          outboundTrips.every((obj1) => obj2.id !== obj1.id)
        )
      );
      setOutboundTrips(newOutboundTrips);
    }
    setSelectedTrips([]);
    const group_id = selectedGroup[0].grouped_trip_id;
    const data = {
      trips: selectedTrips,
      group_id: group_id,
    };
    setIsLoadingPage(true);
    Axios({
      method: 'patch',
      url: `/grouped_trips/${group_id}?context=re_grouping`,
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        const data = res.data.trips;
        if (bound === 'Inbound') {
          data.forEach((trip) => {
            trip.location_latitude += Math.random() / 2500;
            trip.location_longitude += Math.random() / 2500;
          });
          let newInboundGroupedTrips = Object.assign({}, inboundGroupedTrips);
          newInboundGroupedTrips[group_id] = data;
          setInboundGroupedTrips(newInboundGroupedTrips);
          setSelectedGroup(newInboundGroupedTrips[group_id]);
        } else if (bound === 'Outbound') {
          data.forEach((trip) => {
            trip.destination_latitude += Math.random() / 2500;
            trip.destination_longitude += Math.random() / 2500;
          });
          let newOutboundGroupedTrips = Object.assign({}, outboundGroupedTrips);
          newOutboundGroupedTrips[group_id] = data;
          setOutboundGroupedTrips(newOutboundGroupedTrips);
          setSelectedGroup(newOutboundGroupedTrips[group_id]);
        }
        retrieveAssignedGroups();
        retrieveUnassignedGroups();
        setIsLoadingPage(false);
      })
      .catch((err) => {
        setIsLoadingPage(false);
        console.error(err);
      });
  };

  const handleUngroupAllTrips = (trips) => {
    let groupId = trips[0].grouped_trip_id;
    if (bound === 'Inbound') {
      const newInboundTrips = [...inboundTrips, ...trips];
      setInboundTrips(newInboundTrips);
    } else if (bound === 'Outbound') {
      const newOutboundTrips = [...outboundTrips, ...trips];
      setOutboundTrips(newOutboundTrips);
    }

    Axios({
      method: 'patch',
      url: `/grouped_trips/${groupId}/ungroup_all_trips`,
      data: {},
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        const data = res.data.trips;
        if (bound === 'Inbound') {
          let newInboundGroupedTrips = Object.assign({}, inboundGroupedTrips);
          newInboundGroupedTrips[groupId] = data;
          if (isEmpty(data)) {
            delete newInboundGroupedTrips[groupId];
            setSelectedGroup([]);
          } else {
            data.forEach((trip) => {
              trip.location_latitude += Math.random() / 2500;
              trip.location_longitude += Math.random() / 2500;
            });
            setSelectedGroup(newInboundGroupedTrips[groupId]);
          }
          setInboundGroupedTrips(newInboundGroupedTrips);
        } else if (bound === 'Outbound') {
          let newOutboundGroupedTrips = Object.assign({}, outboundGroupedTrips);
          newOutboundGroupedTrips[groupId] = data;
          if (isEmpty(data)) {
            delete newOutboundGroupedTrips[groupId];
            setSelectedGroup([]);
          } else {
            data.forEach((trip) => {
              trip.destination_latitude += Math.random() / 2500;
              trip.destination_longitude += Math.random() / 2500;
            });
            setSelectedGroup(newOutboundGroupedTrips[groupId]);
          }
          setOutboundGroupedTrips(newOutboundGroupedTrips);
        }

        retrieveAssignedGroups();
        retrieveUnassignedGroups();
        setIsLoadingPage(false);
      })
      .catch((err) => console.log(err));
  };

  // Patch request to update action of grouped_trips controller for un-grouping of trips

  const handleUngroup = (e, loc) => {
    if (bound === 'Inbound') {
      const newInboundTrips = inboundTrips;
      newInboundTrips.push(loc);
      setInboundTrips(newInboundTrips);
    } else if (bound === 'Outbound') {
      const newOutboundTrips = outboundTrips;
      newOutboundTrips.push(loc);
      setOutboundTrips(newOutboundTrips);
    }
    let group_id = 0;
    if (selectedGroup[0]) {
      group_id = selectedGroup[0].grouped_trip_id;
    } else {
      group_id = loc.grouped_trip_id;
    }
    const data = {
      trip: loc,
      group_id: group_id,
    };
    setIsLoadingPage(true);
    Axios({
      method: 'patch',
      url: `/grouped_trips/${group_id}?context=ungrouping`,
      data: data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
      .then((res) => {
        const data = res.data.trips;
        if (bound === 'Inbound') {
          let newInboundGroupedTrips = Object.assign({}, inboundGroupedTrips);
          newInboundGroupedTrips[group_id] = data;
          if (isEmpty(data)) {
            delete newInboundGroupedTrips[group_id];
            setSelectedGroup([]);
          } else {
            data.forEach((trip) => {
              trip.location_latitude += Math.random() / 2500;
              trip.location_longitude += Math.random() / 2500;
            });
            setSelectedGroup(newInboundGroupedTrips[group_id]);
          }
          setInboundGroupedTrips(newInboundGroupedTrips);
        } else if (bound === 'Outbound') {
          let newOutboundGroupedTrips = Object.assign({}, outboundGroupedTrips);
          newOutboundGroupedTrips[group_id] = data;
          if (isEmpty(data)) {
            delete newOutboundGroupedTrips[group_id];
            setSelectedGroup([]);
          } else {
            data.forEach((trip) => {
              trip.destination_latitude += Math.random() / 2500;
              trip.destination_longitude += Math.random() / 2500;
            });
            setSelectedGroup(newOutboundGroupedTrips[group_id]);
          }
          setOutboundGroupedTrips(newOutboundGroupedTrips);
        }
        retrieveAssignedGroups();
        retrieveUnassignedGroups();
        setIsLoadingPage(false);
      })
      .catch((err) => {
        setIsLoadingPage(false);
        console.error(err);
      });
  };

  //Drivers and Assigning

  const retrieveAssignedGroups = () => {
    let url = '/grouped_trips';
    url += `?date=?`;
    url += `&timeslot=${null}`;
    url += `&company=${selectedCompany}`;
    url += `&all=${true}`;
    url += '&filter=assigned';
    setIsLoadingPage(true);
    Axios({
      method: 'get',
      url: url,
      headers: { Accept: 'application/json' },
    })
      .then((res) => {
        setAssignedGroups(res.data.grouped_trips);
        setIsLoadingPage(false);
      })
      .catch((err) => {
        setIsLoadingPage(false);
        console.error(err);
      });
  };

  const retrieveUnassignedGroups = () => {
    let url = '/grouped_trips';
    url += `?date=?`;
    url += `&timeslot=${null}`;
    url += `&company=${selectedCompany}`;
    url += `&all=${true}`;
    url += '&filter=unassigned';
    setIsLoadingPage(true);
    Axios({
      method: 'get',
      url: url,
      headers: { Accept: 'application/json' },
    })
      .then((res) => {
        //createGroupRefs(res.data.grouped_trips);
        setUnassignedGroups(res.data.grouped_trips);
        setIsLoadingPage(false);
      })
      .catch((err) => {
        setIsLoadingPage(false);
        console.error(err);
      });
  };

  useEffect(() => {
    retrieveAssignedGroups();
    retrieveUnassignedGroups();
  }, [selectedCompany, selectedAddress, refresh]);

  // get drivers
  useEffect(() => {
    Axios({
      method: 'get',
      url: `/vehicles/drivers?company=${selectedCompany}`,
      headers: { Accept: 'application/json' },
    })
      .then((res) => {
        setDrivers(res.data);
        setIsLoadingPage(false);
      })
      .catch((err) => {
        setIsLoadingPage(false);
        console.error(err);
      });
  }, [selectedCompany, assignedGroups]);

  // return

  //assign driver
  const updateDriver = (selectedDriver, groupId, tripReason = null) => {
    setIsLoadingPage(true);
    Axios({
      method: 'put',
      url: `/grouped_trips/${groupId}/assign_vehicle`,
      headers: { Accept: 'application/json' },
      data: {
        vehicle: selectedDriver.id,
        trip_reason: tripReason,
      },
    })
      .then((_res) => {
        retrieveAssignedGroups();
        retrieveUnassignedGroups();
        setIsLoadingPage(false);
      })
      .catch((err) => {
        setIsLoadingPage(false);
        console.error(err);
      });
  };

  //assign driver
  const updateFleet = (selectedFleet, groupId) => {
    setIsLoadingPage(true);
    Axios({
      method: 'put',
      url: `/grouped_trips/${groupId}/assign_fleet`,
      headers: { Accept: 'application/json' },
      data: {
        fleet: selectedFleet.id,
      },
    })
      .then((_res) => {
        retrieveAssignedGroups();
        retrieveUnassignedGroups();
        setIsLoadingPage(false);
      })
      .catch((err) => {
        setIsLoadingPage(false);
        console.error(err);
      });
  };

  const openInGoogleMaps = (groupId) => {
    let groupedTrip = groupTripSet.find(
      (groupSet) => groupSet.group_id == groupId
    );

    if (groupedTrip == null) return;

    let order = groupedTrip?.tripDetails?.order?.split(',') || [];
    let destinationCoords = null;
    let originCoords = null;
    let waypoints = [];

    if (bound.toLocaleLowerCase() == 'inbound') {
      let originTrip = groupedTrip.trips.find((trip) => trip.id == order[0]);
      originCoords = `${originTrip.location_latitude},${originTrip.location_longitude}`;
      destinationCoords = `${originTrip.destination_latitude},${originTrip.destination_longitude}`;
      order.shift();
    } else if (bound.toLowerCase() == 'outbound') {
      let destinationTrip = groupedTrip.trips.find(
        (trip) => trip.id == order[order.length - 1]
      );
      destinationCoords = `${destinationTrip.destination_latitude},${destinationTrip.destination_longitude}`;
      originCoords = `${destinationTrip.location_latitude},${destinationTrip.location_longitude}`;
      order.pop();
    }

    if (order > 0) {
      waypoints = order.map((tripId) => {
        let waypointTrip = groupedTrip.trips.find((trip) => trip.id == tripId);
        let coords =
          bound.toLowerCase() == 'inbound'
            ? `${waypointTrip.location_latitude},${waypointTrip.location_longitude}`
            : `${waypointTrip.destination_latitude},${waypointTrip.destination_longitude}`;
        return coords;
      });
    }

    let link = 'https://www.google.com/maps/dir/?api=1&';
    link += `origin=${originCoords}&`;
    link += `destination=${destinationCoords}&`;
    link += `waypoints=`;
    link += waypoints.join('|');
    window.open(link, '_blank').focus();
  };

  // Clear selection on date, time, filter and bound change
  useEffect(() => {
    setSelectedGroup([]);
    setSelectedTrips([]);
    setItemToHighLight(null);
    setGroupToHighLight(null);
  }, [
    selectedDate,
    timeSlot,
    bound,
    outboundGroupedTrips,
    inboundGroupedTrips,
  ]);

  // useEffect(() => {
  //   if (selectedAddress != 'None'){
  //     setSelectedAddress('None')
  //   }
  // }, [selectedCompany]);

  useEffect(() => {
    if (selectedAddress != 'None') {
      const address = selectedAddress;

      if (allTrips.hasOwnProperty(address)) {
        setInboundTrips(allTrips[address].inbound);
        setOutboundTrips(allTrips[address].outbound);
      } else {
        setInboundTrips([]);
        setOutboundTrips([]);
      }
    }
  }, [allTrips]);

  useEffect(() => {
    if (selectedAddress != 'None') {
      const address = selectedAddress;

      if (allGroups?.hasOwnProperty(address)) {
        setInboundGroupedTrips(allGroups[address].inbound);
        setOutboundGroupedTrips(allGroups[address].outbound);
      } else {
        setInboundGroupedTrips([]);
        setOutboundGroupedTrips([]);
      }
    }
  }, [allGroups]);

  useEffect(() => {
    if (selectedAddress != 'None') {
      const address = selectedAddress;
      if (allAsssignedGroups?.hasOwnProperty(address)) {
        setInboundAssignedGroupedTrips(allAsssignedGroups[address].inbound);
        setOutboundAssignedGroupedTrips(allAsssignedGroups[address].outbound);
      } else {
        setInboundAssignedGroupedTrips([]);
        setOutboundAssignedGroupedTrips([]);
      }
    }
  }, [allAsssignedGroups]);

  return (
    <div className="h-screen w-screen flex flex-col">
      <div className="w-full">
        <div className="flex flex-row">
          <h1 className="text-xl font-medium text-gray-700">
            {' '}
            {currentUser.role == 'fleetmanager' ? 'Grouped Trips' : 'Trips'}
          </h1>
          <button
            className="text-gray-500 cursor-pointer hover:text-gray-900 ml-2"
            onClick={() => managedTraining(true)}>
            <i className="fas fa-question-circle" />
          </button>
        </div>
        <p className="text-sm text-gray-500">{`Logged in as ${currentUser.name} ${currentUser.surname}`}</p>
      </div>
      <div className="flex-1 relative">
        {role != 'fleetmanager' && (
          <EnhancedTripMap
            trips={filteredTrips}
            handleMarkerClick={handleMarkerClick}
            handleGroupMarkerClick={handleGroupMarkerClick}
            handleAssignedMarkerClick={handleAssignedMarkerClick}
            selectedGroup={selectedGroup}
            selected={selectedTrips}
            groupedTrips={filteredGroupedTrips}
            assignedTrips={assignedLocations}
            handleCreateGroup={handleCreateGroup}
            handleReGroup={handleReGroup}
            bound={bound}
            handleUngroup={handleUngroup}
            selectedAddress={selectedAddress}
            selectedCompany={selectedCompany}
            companies={companies}
            currentUser={currentUser}
            pageType={pageType}
            setPageType={setPageType}
            selectedDate={selectedDate}
            selectedTimeslot={timeSlot}
            showAssignedTrips={showAssignedTrips}
            showGroupedTrips={showGroupedTrips}
            centerFocus={centerFocus}
            itemToHighLight={itemToHighLight}
            groupToHighLight={groupToHighLight}
            focusPins={focusPins}
          />
        )}
        <div
          className="absolute top-0 right-0 p-4"
          style={{ transform: 'scale(0.9)', transformOrigin: 'top right' }}>
          {/* top right menu */}
          <PassengerMenu
            companies={companies}
            setSelectedCompany={handleCompanySelect}
            selectedCompanyRef={selectedCompanyRef}
            setSelectedAddress={handleAddressSelect}
            selectedAddress={selectedAddress}
            addresses={addresses}
            inboundTrips={inboundTrips}
            outboundTrips={outboundTrips}
            setInboundTrips={setInboundTrips}
            setOutboundTrips={setOutboundTrips}
            setOutboundGroupedTrips={setOutboundGroupedTrips}
            setInboundGroupedTrips={setInboundGroupedTrips}
            allGroups={allGroups}
            allAsssignedGroups={allAsssignedGroups}
            allTrips={allTrips}
            setAllTrips={setAllTrips}
            setAllGroups={setAllGroups}
            bound={bound}
            setBound={setBound}
            setSelectedTrips={setSelectedTrips}
            setSelectedGroup={setSelectedGroup}
            setShowAssignedTrips={setShowAssignedTrips}
            setShowGroupedTrips={setShowGroupedTrips}
            showAssignedTrips={showAssignedTrips}
            showGroupedTrips={showGroupedTrips}
            setInboundAssignedGroupedTrips={setInboundAssignedGroupedTrips}
            setOutboundAssignedGroupedTrips={setOutboundAssignedGroupedTrips}
            role={role}
          />
        </div>
        <div
          className="absolute top-0 left-0 p-4"
          style={{ transform: 'scale(0.9)', transformOrigin: 'top left' }}>
          {/* top left menu */}
          <TripMenu
            setIsLoadingPage={setIsLoadingPage}
            selectedDate={selectedDate}
            bound={bound}
            setSelectedDate={handleDateSelect}
            timeSlot={timeSlot}
            setTimeSlot={handleTimeSelect}
            tripsList={filteredTrips}
            groupedTrips={filteredGroupedTrips}
            pageType={pageType}
            setPageType={setPageType}
            handleUngroup={handleUngroup}
            drivers={drivers}
            updateDriver={updateDriver}
            groupTripSet={groupTripSet}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            setRefresh={handleRefresh}
            selectedCompany={selectedCompany}
            selectedAddress={selectedAddress}
            focusPin={handleCenterFocus}
            companies={companies}
            handleMarkerHighlight={handleMarkerHighlight}
            handleGroupMarkerHighlight={handleGroupMarkerHighlight}
            setFocusPins={setFocusPins}
            role={role}
            merchantFleets={merchantFleets}
            fleets={fleets}
            updateFleet={updateFleet}
            setSelectedFleetFilter={setSelectedFleetFilter}
            selectedFleetFilter={selectedFleetFilter}
            fleetFilterOptions={fleetFilterOptions}
            openInGoogleMaps={openInGoogleMaps}
            handleUngroupAllTrips={handleUngroupAllTrips}
          />
        </div>
        {isLoadingPage && (
          <div className="absolute overflow-hidden top-0 left-0 right-0 bottom-0 w-full h-full z-50 bg-gray-700 opacity-75 flex flex-col items-center justify-center">
            <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
            <h2 className="text-center text-white text-xl font-semibold">
              Loading...
            </h2>
          </div>
        )}
      </div>
      <Steps
        enabled={introState.stepsEnabled}
        steps={introState.steps}
        initialStep={introState.initialStep}
        onExit={() => managedTraining(false)}
        options={introState.options}
      />
    </div>
  );
};

export default EnhancedGroupedTrips;
